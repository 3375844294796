import React, {FunctionComponent, ReactNode} from "react"
import "./sectionStyle.css"

type props ={
    children:ReactNode
}

export const Section: FunctionComponent<props> = ({children}) => {
    return (
        <div className={"section"}>
            {children}
        </div>
    )
}
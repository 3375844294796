import React, {FunctionComponent} from "react"
import "./aboutPageStyle.css"
import bannerImg from "../../assets/contact.jpg"
import cathy from "../../assets/cathy.jpg"
import them from "../../assets/them.jpg"
import doctor from "../../assets/doctor.jpg"
import personCathy from "../../assets/cath.jpg"
import bony from "../../assets/bony.jpg"
import bonite from "../../assets/bonite.jpg"
import mimi from "../../assets/mimi.jpg"
import benji from "../../assets/benj.jpg"
import myriam from "../../assets/myriam.jpg"
import almk from "../../assets/almk.jpg"
import uthinga from "../../assets/uthinga.jpg"
import esengo from "../../assets/esengo.jpg"
import mathieu from "../../assets/mathieu.jpg"
import muana from "../../assets/doudou.png"
import madiya from "../../assets/madiya.jpg"
import komi from "../../assets/komi.jpg"
import {Section} from "../../components/Section";
import {Member} from "../../components/Member";
import {Banner} from "../../components/Banner";
import poster from "../../assets/pp.jpg"
import {AiOutlineCheck} from "react-icons/ai";


export const AboutPage: FunctionComponent = () => {
    return (
        <div className={"about"}>
                <Banner banner={bannerImg} pageTitle={"A propos de nous"} />
            <Section>
                <div className={"about__secOne-container lg__container-padding"}>
                    <div>
                        <h2 className={"home__hero-title"}>NOTRE MISSION</h2>
                        <p style={{color:"#283847"}}>
                            Je vous ai montré de toutes manières que c'est en
                            <span className={"emphasize"}> travaillant</span> ainsi qu'il faut <span className={"emphasize"}>soutenir </span>
                            les <span className={"emphasize"}>faibles</span>, et se rappeler les paroles du
                            <span className={"emphasize"}> Seigneur</span>, qui a dit lui-même:  <span className={"emphasize"}>Il y a plus de bonheur à donner qu'à recevoir. </span>
                            Actes 20:35
                        </p>
                    </div>
                    <div>
                        <h2 className={"home__hero-title"}>NOTRE VISION</h2>
                        <p style={{color:"#283847"}}>
                            Éduquer la population à la pratique des méthodes manuelles et technologiques pour la conservation de leur environnement et de la protection de la nature susceptible de générer des revenus.
                        </p>
                    </div>
                </div>
            </Section>
            <Section>
                <div className={"about__secTwo-container lg__container-padding"}>
                    <div>
                        <img src={poster} className={"about__img"} alt={"people rejoicing"} />
                    </div>
                    <>
                        <div>
                            <h2 className={"section__title"}>Notre histoire</h2>
                            <p className={"section__paragraph"}>
                                Monsieur <span className={"emphasize"}>Bony ISAMENE</span>  a reçu cette recommandation de la part de Dieu de réunir ses frères et sœurs de la diaspora et se mettre ensemble pour aider leur province d’origine.
                                Notre présence à l’extérieur n’était pas un hasard, <span className={"emphasize"}>ADIT</span> a une mission divine.
                            </p>
                            <h2 className={"section__title"}>Fonctionnement </h2>
                            <p className={"section__paragraph"}>
                                Jusqu’à présent, <span className={"emphasize"}>ADIT</span> fonctionne avec les cotisations de ses membres adhérents.
                                Nous récupérons des médicaments et tout ce qui est comme matériels médicales.
                            </p>

                            <h2 className={"section__title"}>Nos succursales</h2>
                            <p className={"section__paragraph"}>
                                Diaspora, Tshopo/Kisangani, Kinshasa et Bunia
                            </p>
                        </div>
                    </>
                </div>
            </Section>
            <Section>
                <div className={"about__secThree-container lg__container-padding"} >
                    <div>
                        <h2 className={"section__title"} >Objectifs court terme </h2>
                        <div className={"about__list"} style={{backgroundColor:"#0C4A6E"}}>
                            <ul>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    La réhabilitation des Home des vieillards
                                </li>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    Campagne de sensibilisation des maladies : Diabète, corps utérus, tension artérielle
                                </li>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    Entretien des cimetières des différentes communes
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <h2 className={"section__title"} >Objectifs moyen et long terme </h2>
                        <div className={"about__list"} style={{backgroundColor:"#0C4A6E"}}>
                            <ul>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    Formation des jeunes dans les métiers
                                </li>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    Curages des caniveaux
                                </li>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    S’investir au couverture maladie universelle
                                </li>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    Installation d’une Polyclinique
                                </li>
                                <li className={"goal__check"}>
                                    <AiOutlineCheck className={"goal__icon"} />
                                    Agriculture
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Section>
            <Section>
                <div className={"section__text-centered paddingUp"}>
                    <small>Notre organisation</small>
                    <h2 className={"section__title"}>Rencontrez l’équipe</h2>
                </div>
                <div className={"about__secFour-wrapper lg__container-padding"}>
                    <div className={"about__memberSection-container"}>
                        <Member member={"Bony Isamene"} position={"Président"} country={"France"} picture={bony}  />
                        <Member member={"Cathy Tribunal Roger"} position={"Co-fondateur"} country={"Suisse"} picture={personCathy}  />
                        <Member member={"Myriam Basosila Mbewa"} position={"Secrétaire"} picture={myriam} />
                        <Member member={"Uthinga Sophie Apopotsa"} position={"Trésorière"} country={"France"} picture={uthinga} />
                        <Member member={"Dorothee Basosila"} position={"Conseillère de projets"} country={"France"} picture={benji} />
                        <Member member={"Mimi Batileuwa"} position={"Membre"} country={"Belgique"} picture={madiya} />
                        <Member member={"Osé Behuhuma"} position={"Membre"} country={"Afrique du Sud"} picture={doctor} />
                        <Member member={"Madiya Agnès"} position={"Donatrice"} country={"France"} picture={cathy} />
                        <Member member={"Benjamin Elongo"} position={"Donateur"} picture={komi} />
                        <Member member={"Komi Behuhuma"} position={"Membre"} country={"USA"} picture={mimi} />
                        <Member member={"Eugénie Hiswamene"} position={"Membre"}  picture={bonite} />
                        <Member member={"Mr & Mme Tshimpuk (Voice of Congo)"} position={"Partenaires ADIT"}  picture={them} />
                        <Member member={"Steve Madwary"} position={"Membre"}  picture={esengo} country={"Japon"} />
                        <Member member={"Mathieu Isamene"} position={"Membre"}  picture={mathieu} country={"Angleterre"} />
                        <Member member={"Roger Mounga"} position={"Donateur"}  picture={muana} />
                        <Member member={"Alphonse Makide Kalume"} position={"Membre"}  picture={almk} country={"Canada"} />




                        <Member member={"Sidonie Behuhuma"} position={"Membre"}  country={"Usa"} />
                        <Member member={"Vero Moleko"} position={"Membre"}  country={"Belgique"} />
                        <Member member={"Margot Seko"} position={"Membre"}  country={"France"} />
                        <Member member={"Jolie Bangala"} position={"Membre"}  country={"Usa"} />

                    </div>
                </div>
            </Section>
        </div>
    )
}
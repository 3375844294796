import React, {FunctionComponent, useState} from "react"
import bannerImg from "../../assets/contact.jpg";
import {Banner} from "../../components/Banner";
import {Section} from "../../components/Section";
import "./contactPageStyle.css"
import {BusinessInfo} from "../../components/BusinessInfo";
// import {BsEnvelope} from "react-icons/tb";
import {BsTelephone, BsEnvelope} from "react-icons/bs";
import {GoLocation} from "react-icons/go";
import {TextInput} from "../../components/textInput/TextInput";
import {TextArea} from "../../components/textInput/TextArea";
import {Button} from "../../components/Button";
import {SelectInput} from "../../components/textInput/SelectInput";


export const ContactPage: FunctionComponent = () => {
    const [contact, setContact] = useState({fullName:"", email:"", phone:"", subject:"", message:""})
    const handleOnChange =(e:any)=> setContact({...contact, [e.target.name]:e.target.value})
    return (
        <div className={"contact"}>
            <Banner banner={bannerImg} pageTitle={"Contact"} />
            <Section>

                <div className={"contact__secOne-container"}>
                    <div className={"contact__title"}>
                        <h2 className={"section__title"}>Laissez nous un message</h2>
                        <p className={"section__paragraph"}>Nous sommes disposés à répondre à toutes vos préoccupations.</p>
                    </div>

                    <div className={"contact__infoCard"}>

                        <BusinessInfo icon={<BsEnvelope className={"statistic__icon"} />} title={"Email"} data={"contact@adit-diaspora.com"} />
                        <BusinessInfo icon={<BsTelephone className={"statistic__icon"} />} title={"Téléphone"} data={"+33 6 66 91 46 06"} />
                        <BusinessInfo icon={<GoLocation className={"statistic__icon"} />} title={"Adresse"} data={"37270 Montlouis-sur-Loire / France"} />

                    </div>

                    <div>
                        <form className={"form__container"} onSubmit={(e)=>{
                            e.preventDefault()
                            console.log("Your request info", contact)
                        }}>
                            <div className={"form__el-wrapper"}>

                                <TextInput label={"Nom complet"} type={"text"} name={"fullName"} onChange={handleOnChange} placeholder={"Votre nom"} />
                                <TextInput label={"Email"} type={"email"} name={"email"} onChange={handleOnChange} placeholder={"Votre email"} />

                            </div>
                            <div className={"form__el-wrapper"}>

                                <TextInput type={"tel"} label={"Téléphone"} name={"phone"} onChange={handleOnChange} placeholder={"Votre numéro"} />
                                <SelectInput type={"tel"} label={"Objet"} name={"subject"} onChange={handleOnChange}  />

                            </div>
                            <div className={"form__el-wrapper"}>
                                <TextArea  label={"Votre message"} name={"message"} onChange={handleOnChange} placeholder={"Votre message"} />

                            </div>

                            <div>
                                <div  style={{height:'2rem'}}/>
                                <button className={"btn"} type={"submit"} >Envoyer</button>
                            </div>
                        </form>
                    </div>

                </div>

            </Section>

        </div>
    )
}